import React from 'react'
import styled from '@emotion/styled'
import { breakpoints, container, colors, button } from '../../styles/theme'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import threadSrc from '../../img/single-thread.svg'

const MadeToMeasureContent = () => {
  const { illustration } = useStaticQuery(graphql`
    {
      illustration: file(
        relativePath: { eq: "img/measuring-tape-illustration.png" }
      ) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Container>
        <Image
          {...illustration.childImageSharp}
          alt="Suit tailor's measuring tape illustration"
          style={{ display: 'block', margin: '0 auto' }}
        />

        <Heading className="heading--h1">
          <span>Made to Measure</span> Tailoring
        </Heading>
        <p>Do you have problems with fit and style?</p>
        <p>Do you wonder why certain colours don’t complement you?</p>
        <p>
          Do you feel awkward talking to someone about your image or feel that
          what you are wearing is not comfortable?
        </p>
        <p>
          Do you feel that what you wear is not suitable for your lifestyle or
          your business environment?
        </p>
        <p>
          <em>Do you want to look amazing?</em>
        </p>
        <p>
          <strong>
            If any of these common tailoring problems affect you then a
            consultation with John Parrett and Fox Tailoring is the perfect
            solution.
          </strong>
        </p>
        <CallToAction to="/contact/">Book an Appointment</CallToAction>
      </Container>
    </Wrapper>
  )
}

export default MadeToMeasureContent

const Wrapper = styled.div`
  background: url(${threadSrc});
  background-size: cover;
  background-position: center right;
  padding-bottom: 32px;

  @media (min-width: ${breakpoints.medium}px) {
    margin-right: auto;
    width: 50%;
    max-width: ${breakpoints.large / 2}px;
  }
`

const Container = styled.div`
  ${container};
  max-width: 440px;
  padding-top: 32px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakpoints.medium}px) {
    padding-top: 72px;
  }
`

const Heading = styled.h1`
  font-size: 3.8rem;
  line-height: 1.3;
  > span {
    border-bottom: 1px solid ${colors.accent};
  }
`

const CallToAction = styled(Link)`
  ${button.default};
  ${button.outline};
  margin-top: 32px;
  color: ${colors.accent};
  border-color: ${colors.accent};
`
