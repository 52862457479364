import React from 'react'
import styled from '@emotion/styled'
import { colors, breakpoints } from '../../styles/theme'

import HeroImage from './hero-image'
import Content from './content'

const MadeToMeasure = () => (
  <Wrapper>
    <MaskedHeroImage />
    <Content />
  </Wrapper>
)

const Wrapper = styled.section`
  background: ${colors.primary};
  color: ${colors.white};

  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.medium}px) {
    flex-direction: row;

    > * {
      flex-basis: 50%;
    }
  }
`

const MaskedHeroImage = styled(HeroImage)`
  @media (max-width: ${breakpoints.medium - 1}px) {
    max-height: 400px;
    border-bottom: 1px solid ${colors.midGrey};
  }

  @media (min-width: ${breakpoints.medium}px) and (max-width: ${breakpoints.large -
    1}px) {
    mask-image: linear-gradient(to left, transparent, black 15%);
  }
`

export default MadeToMeasure
