import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const HeroImage = ({ className }) => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/john-parrett-50s-suit-portrait.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1200, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Image
      className={className}
      {...hero.childImageSharp}
      alt="Navy Blue Pinstripe Tailored Suit with Pink Tie"
      imgStyle={{
        left: 'auto',
        right: 0,
        objectPosition: 'top',
        fontFamily: 'object-fit: cover, object-position: top',
      }}
    />
  )
}

export default HeroImage
