import React from 'react'
import Seo from '../components/SEO'
import Layout from '../components/layout'
import MadeToMeasure from '../components/made-to-measure'
import PageBody, { PageContainer } from '../components/shared/page-body'
import Interview from '../components/interview-video'

const MadeToMeasurePage = () => (
  <Layout>
    <Seo
      title="Made to Measure Tailoring"
      description="How Made to Measure tailoring delivers style for your lifestyle, age, body shape, colour and comfort."
      pathname="/made-to-measure-tailoring"
    />
    <MadeToMeasure />
    <PageBody>
      <PageContainer maxWidth={760} padded>
        <h2 className="heading--h1">What happens next&hellip;?</h2>
        <p>
          You will meet with John, who will confidentially discuss with you the
          problem you have identified. This usually takes an hour or so for the
          consultation.
        </p>
        <p>
          Whether it be fit, style, colour or functionality, John will firstly
          advise you on the fabrics you need to consider for the occasion you
          have in mind either for a wedding, just social, business, classic,
          fashion or just to be different.
        </p>
        <p>
          The product range available is extremely varied&hellip; Mens suits,
          jackets, waistcoats, trousers and shirts as well as Ladies jackets,
          trousers and skirts.
        </p>
        <p>
          Style and image will be recommended for your lifestyle, age, body
          shape, colour and comfort.
        </p>
        <p>
          Fabrics vary from classic business outfits through to 'wacky and
          trendy' multi- coloured checks, plains and stripes. Using some of the
          best cloth merchants in the business such as Scabal of Savile Row,
          Loro Piana of Italy, Dugdale of Leeds, Harrisons, Bateman & Ogden
          and Huddersfield Fine Worsteds, to name just a few.
        </p>
        <p>
          The aim is for the client to leave the showroom with an outfit that he
          or she is totally satisfied with, is unique to them and not
          seen anywhere.
        </p>
        <p>
          It is a total experience that should not be missed and it can be
          life-changing.
        </p>
        <p>
          Once fabrics and linings (plain, fancy and wacky) have been selected,
          styling is the next step.
        </p>
        <p>
          Styling enables Fox Tailoring to accentuate or camouflage body shapes
          and this skill is vital to the end result. At the end of the day, it
          will be the right answer for you. Many measurements will be taken and
          templates of patterns will be put on you to show the differences good
          styling will make to your body shape and how it will improve your
          posture and confidence.
        </p>
        <p>
          In addition extra detailing can be added to your garment, making it
          individual, different and unique to you. Each garment is individually
          cut and made for you.
        </p>
        <p>
          Whether you are leaving school, college or university or attending
          your granddaughter’s wedding&hellip;
        </p>
        <p>
          <strong>FOX TAILORING WILL CREATE THE IDEAL LOOK FOR YOU.</strong>
        </p>
      </PageContainer>
      <Interview />
    </PageBody>
  </Layout>
)

export default MadeToMeasurePage
